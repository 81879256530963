
import { defineComponent, onMounted, ref } from 'vue';
import GridLine from '@/components/GridLine.vue';
import ScrollMagic from 'scrollmagic';
/* eslint-disable max-len */

export default defineComponent({
  name: 'Home',
  components: {
    GridLine,
  },
  setup() {
    const sceneAnchor = {
      scene1: ref(true),
      scene2: ref(false),
      scene3: ref(false),
      scene4: ref(false),
      scene5: ref(false),
      scene6: ref(false),
    };

    let scenes: Array<ScrollMagic.Scene> = [];

    const initScrollMagic = (): ScrollMagic.Scene[] => {
      const controller = new ScrollMagic.Controller();
      const elementHeight = (): number => document.querySelector<HTMLElement>('.scroll-nav-content')?.offsetHeight || 0;
      const sceneNavHeight = (): number => document.querySelector<HTMLElement>('#container-scene')?.offsetHeight || 0;

      const sceneNav = new ScrollMagic.Scene({
        triggerHook: 0,
        duration: 0,
        triggerElement: '#container-scene',
      }).setPin('#container-scene').addTo(controller);

      const scene1 = new ScrollMagic.Scene({
        triggerHook: 0,
        duration: 0,
        triggerElement: '#scene-pin1',
      }).setPin('#scene-pin1').addTo(controller);

      const scene2 = new ScrollMagic.Scene({
        triggerHook: 0,
        duration: 0,
        triggerElement: '#scene-pin2',
      }).setPin('#scene-pin2').addTo(controller);

      const scene3 = new ScrollMagic.Scene({
        triggerHook: 0,
        duration: 0,
        triggerElement: '#scene-pin3',
      }).setPin('#scene-pin3').addTo(controller);

      const scene4 = new ScrollMagic.Scene({
        triggerHook: 0,
        duration: 0,
        triggerElement: '#scene-pin4',
      }).setPin('#scene-pin4').addTo(controller);

      const scene5 = new ScrollMagic.Scene({
        triggerHook: 0,
        duration: 0,
        triggerElement: '#scene-pin5',
      }).setPin('#scene-pin5').addTo(controller);

      const scene6 = new ScrollMagic.Scene({
        triggerHook: 0,
        duration: 0,
        triggerElement: '#scene-pin6',
      }).setPin('#scene-pin6').addTo(controller);

      const clearScene = () => {
        sceneAnchor.scene1.value = false;
        sceneAnchor.scene2.value = false;
        sceneAnchor.scene3.value = false;
        sceneAnchor.scene4.value = false;
        sceneAnchor.scene5.value = false;
        sceneAnchor.scene6.value = false;
      };

      const updateSceneDuration = (): void => {
        const windowHeight = window.innerHeight || 0;
        sceneNav.on('update', ((e) => {
          e.currentTarget.duration(elementHeight() - sceneNavHeight());
        }));
        scene1.on('update enter', ((e) => {
          switch (e.type) {
            case 'enter':
              clearScene();
              sceneAnchor.scene1.value = true;
              break;
            case 'update':
              e.currentTarget.duration(windowHeight);
              break;
            default:
          }
        }));
        scene2.on('update enter', ((e) => {
          switch (e.type) {
            case 'enter':
              clearScene();
              sceneAnchor.scene2.value = true;
              break;
            case 'update':
              e.currentTarget.duration(windowHeight);
              break;
            default:
          }
        }));
        scene3.on('update enter', ((e) => {
          switch (e.type) {
            case 'enter':
              clearScene();
              sceneAnchor.scene3.value = true;
              break;
            case 'update':
              e.currentTarget.duration(windowHeight);
              break;
            default:
          }
        }));
        scene4.on('update enter', ((e) => {
          switch (e.type) {
            case 'enter':
              clearScene();
              sceneAnchor.scene4.value = true;
              break;
            case 'update':
              e.currentTarget.duration(windowHeight);
              break;
            default:
          }
        }));
        scene5.on('update enter', ((e) => {
          switch (e.type) {
            case 'enter':
              clearScene();
              sceneAnchor.scene5.value = true;
              break;
            case 'update':
              e.currentTarget.duration(windowHeight);
              break;
            default:
          }
        }));
        scene6.on('update enter', ((e) => {
          switch (e.type) {
            case 'enter':
              clearScene();
              sceneAnchor.scene6.value = true;
              break;
            case 'update':
              e.currentTarget.duration(windowHeight);
              break;
            default:
          }
        }));
        setTimeout(() => {
          clearScene();
        }, 0);
      };

      updateSceneDuration();

      return [
        sceneNav,
        scene1,
        scene2,
        scene3,
        scene4,
        scene5,
        scene6,
      ];
    };

    const destroyScenes = (): void => {
      scenes.map((scene: ScrollMagic.Scene) => scene.destroy(true));
      scenes.length = 0;
    };

    const init = (): void => {
      if (window.innerWidth > 768 && document.querySelector('[data-scrollmagic-pin-spacer]') === null) {
        scenes = [...initScrollMagic()];
      } else if (window.innerWidth <= 768 && scenes.length) {
        destroyScenes();
      }
    };

    onMounted((): void => {
      init();
      window.addEventListener('resize', () => {
        init();
      });
    });

    return {
      scene1: sceneAnchor.scene1,
      scene2: sceneAnchor.scene2,
      scene3: sceneAnchor.scene3,
      scene4: sceneAnchor.scene4,
      scene5: sceneAnchor.scene5,
      scene6: sceneAnchor.scene6,
    };
  },
});
